//import Bugsnag from '@bugsnag/expo';

import React, {Suspense, useEffect, useRef, useState} from 'react';
import {Provider} from 'react-redux';
import './src/i18n';
import ResponsiveNavigation from "./src/navigations/ResponsiveNavigation";
import {store} from "./src/store";
import NativeBaseProviderWithCustomTheme from "./src/components/NativeBaseProviderWithCustomTheme";
import {Text, Image, View, Animated, StyleSheet} from "react-native";
import * as Notifications from 'expo-notifications';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Platform} from "react-native";
import * as Device from "expo-device";
import * as ScreenOrientation from "expo-screen-orientation";
import * as Sentry from 'sentry-expo';
import Config from "./src/config.json";
import LoadingFallback from "./src/components/LoadingFallback";
import sponsoredSplashMobileIMCAS from './assets/sponsored_splash_mobile.png';
import sponsoredSplashTabletLandscapeIMCAS from './assets/sponsored_splash_tablet_landscape.png';
import sponsoredSplashTabletPortraitIMCAS from './assets/sponsored_splash_tablet_portrait.png';
import HttpService from "./src/services/HttpService";
import FirebaseAnalytics from "./src/services/Analytics/Analytics";

let isSplashScreen = false
if (Platform.OS === 'android' || Platform.OS === 'ios') {
//    Bugsnag.start();
}

Sentry.init({
    dsn: 'https://0eed0cbdc200495e9e4435d2aaf9b508@o397323.ingest.sentry.io/6466118',
    enableInExpoDevelopment: true,
    debug: (Config.environment !== 'production'),
    // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

/**
 * Metro bundler does not support dynamic imports, so we have to have this ugly rigid logic
 * to support aop/imcas assets differences at compile time
 */

export default function App() {
    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const [appReady, setAppReady] = useState(false);
    const [isSplashScreen, setIsSplashScreen] = useState(false);
    const [splashScreenUrls, setSplashScreenUrls] = useState('');
    const [deviceType, setDeviceType] = useState(0);
    const [landscape, setLandscape] = useState(null);
    const notificationListener = useRef();
    const responseListener = useRef();

    useEffect(() => {
        setAppReady(false);
        registerForPushNotificationsAsync().then(token => {
            setExpoPushToken(token);
        });

        getDeviceType().then((type) => {
            setDeviceType(type);
            if (type === 2) {
                setLayout().then(() => switchAppToReady(4000));
            } else switchAppToReady(4000);
        });

        // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
            setNotification(notification);
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
            console.log(response);
        });

        if (Platform.OS !== 'web') {
            HttpService
                .getData('ads/splashscreen/has')
                .then(response => {
                    setIsSplashScreen(response.data)
                })
        }

        return () => {
            Notifications.removeNotificationSubscription(notificationListener.current);
            Notifications.removeNotificationSubscription(responseListener.current);
        };

    }, []);

    async function setLayout() {
        const orientation = await ScreenOrientation.getOrientationAsync();

        if (orientation === 3 || orientation === 4) {
            setLandscape(true);
        } else setLandscape(false);
    }

    function switchAppToReady(timeout) {
        setTimeout(() => setAppReady(true), timeout);
    }

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    async function recordAddViewed() {
        return await FirebaseAnalytics.sendAnalyticsEventAsync('screen_view', {screen_name: 'sponsored_splash'});
    }

    useEffect(() => {
        if (isSplashScreen) {
            HttpService
                .getData('ads/splashscreen/get')
                .then(response => {
                    setSplashScreenUrls({
                        'mobile': response.data.mobile.image_file_url,
                        'tablet_landscape': response.data.tablet_landscape.image_file_url,
                        'tablet_portrait': response.data.tablet_portrait.image_file_url
                    });
                });
        }
    }, [isSplashScreen]);

    const styles = StyleSheet.create({
        splash: {
            width: '100%',
            height: '100%'
        }
    })


    if (!appReady && Platform.OS !== 'web' && isSplashScreen) {

        let image = splashScreenUrls.mobile;
        if (deviceType === 2 && landscape) image = splashScreenUrls.tablet_landscape;
        else if (deviceType === 3 && !landscape) image = splashScreenUrls.tablet_portrait;
 
        recordAddViewed().then(() => console.log('sponsored_splash'))
        return (
            <Provider store={store}>
                <NativeBaseProviderWithCustomTheme>
                    <View style={{width: '100%', height: '100%', backgroundColor: '#fff'}}>
                        <Image
                            source={{uri: image}}
                            alt={'Splash image from sponsor'}
                            resizeMode={'contain'}
                            style={styles.splash}
                        />
                    </View>
                </NativeBaseProviderWithCustomTheme>
            </Provider>
        );
    } else
        return (
            <Provider store={store}>
                <NativeBaseProviderWithCustomTheme>
                    <Suspense fallback={<LoadingFallback deviceType={deviceType}/>}>
                        <ResponsiveNavigation></ResponsiveNavigation>
                    </Suspense>
                </NativeBaseProviderWithCustomTheme>
            </Provider>
        );

}

async function registerForPushNotificationsAsync() {
    let token;
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
        const {status: existingStatus} = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
            const {status} = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            console.log('Failed to get push token for push notification!');
            return false;
        } else {
            let token = '';
            Notifications.getExpoPushTokenAsync({experienceId: 'imcas/imcas-live'})
                .then((r) => {
                    token = r.data;

                    try {
                        return AsyncStorage.setItem('expo-push-token', token);
                    } catch (e) {
                        return false;
                    }
                })
                .catch(err => console.log(err));
        }
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
}
