import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Flex, HStack, Spacer, Text, useTheme,
} from "native-base";
import LinkBtn from "./LinkBtn";
import {Dimensions, Platform} from "react-native";
import * as Device from "expo-device";
import {Link} from "@react-navigation/native";

const CompanyListCard = ({company, cardWidth}) => {
    const {colors} = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const [deviceType,setDeviceType] = useState();
    const uniqueId = () => {
        const dateString = Date.now().toString(36);
        const randomness = Math.random().toString(36).substr(2);
        return dateString + randomness;
    };

    useEffect(
        () => {
            async function setLayout() {
                const type = await getDeviceType();
                setDeviceType(type);
            }
            setLayout();
        }, []
    );

    async function getDeviceType() {
        return await Device.getDeviceTypeAsync();
    }

    function hoverOn() {
        return setIsHovered(true);
    }

    function hoverOff() {
        return setIsHovered(false);
    }

    const renderCompanyListCard = () => {
        if (Platform.OS === 'web'|| (deviceType == 2 && Dimensions.get('window').width > Dimensions.get('window').height)) {
            return (
                <Flex
                    key={uniqueId()}
                    justifyContent="center"
                    alignItems="center"
                    w={cardWidth}
                    height={{base:"100%",lg:"225px"}}
                    m={3}
                    p={5}
                    borderWidth={1}
                    borderRadius='md'
                    bg={isHovered ? colors['background'][100]['500'] : colors['background'][100]}
                    onMouseEnter={hoverOn}
                    onMouseLeave={hoverOff}
                >
                    <Link to={{
                        screen: 'CompanyProfile',
                        params: {id: company.id}
                    }}  style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        zIndex: 3,
                        elevation: 3,
                    }}>
                    </Link>
                        <Flex justifyContent="center" alignItems="center">
                            <Avatar size="100px"
                                    source={{uri: company.picture ? company.picture : null}}
                                    my={5}/>
                            <Flex justifyContent="center" alignItems="center">
                                <Text fontSize="sm" bold>{company?.sponsor_company_name ? company?.sponsor_company_name?.toUpperCase() : company?.company_name?.toUpperCase()}</Text>
                            </Flex>
                            <Spacer/>
                        </Flex>
                </Flex>
            )
        }else {
            return (
                <>
                    <Link to={{screen: 'CompanyProfile', params: {id: company.id}}}
                             style={{
                                 width: '100%',
                                 height: '100%',
                                 position: 'absolute',
                                 zIndex: 3,
                                 elevation: 3,
                             }}
                    >
                    </Link>
                    <Flex w="100%" py={2}
                    >
                        <HStack alignItems="center" width="100%">
                            <Avatar size="md" source={{uri: company.picture ? company.picture : null}}/>
                            <Flex flex={(4)} width={'100%'} flexWrap={'wrap'} p={2}>
                                <Text fontSize="sm" bold ml={2} width={'100%'}>{company?.sponsor_company_name?.toUpperCase()}</Text>
                            </Flex>
                        </HStack>

                    </Flex>
                </>
            )
        }
    }

    return <>{renderCompanyListCard()}</>

};

CompanyListCard.defaultProps = {
    cardWidth:'25%',
}

export default CompanyListCard;




