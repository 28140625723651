import {Box, Button, Center, Flex, Heading, Spinner, Stack, Text, useTheme, View} from "native-base";
import {AntDesign} from "@expo/vector-icons";
import * as React from "react";
import {useNavigation} from "@react-navigation/native";
import {useTranslation} from "react-i18next";
import MenuButton from "../components/MenuButton";
import SupportButton from "../components/SupportButton";
import MenuLink from "../components/MenuLink";
import HomeNews from "../components/HomeNews";
import * as Device from "expo-device";
import {Image, ImageBackground, Platform} from "react-native";
import Config from "../config.json";
import bannerImageAop from "../../assets_aop/homepage_banner.jpg";
import bannerImage from "../../assets/homepage_banner.jpg";
import {useSelector} from "react-redux";


export default function LayoutFoMobile(props) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const navigation = useNavigation();
    const [deviceType,setDeviceType] = React.useState();
    const {congress} = useSelector(state => state.global);



    function goToPreviousPage() {
        return navigation.goBack();
    }

    React.useEffect(() => {
        async function setLayout() {
            const type = await Device.getDeviceTypeAsync();
            setDeviceType(type);
        }
        setLayout();
    }, []);

    function renderHeaderBanner() {
        let headerBannerTitle;
        const typeTitle = congress.app_name.split('-')[0].trim();

        headerBannerTitle = <>
            <Heading  fontSize={'18px'} fontWeight={'medium'} color={'white'} ml={'5px'}>
                {typeTitle}
            </Heading>
            <Text fontSize={'13px'} fontWeight={'bold'} color={colors['action'][50]} mt={'-8px'} ml={'5px'}>
                {congress.name_short}
            </Text>
        </>;
        return (
            <View h={'40px'} width={'150px'} borderRadius={10} >
                <ImageBackground
                    source={Config.type === 'aop' ? bannerImageAop : bannerImage}
                    imageStyle={{opacity: 0.25,borderRadius: 10}}
                    style={{width: '100%', height: '100%', justifyContent: 'center',borderRadius: 10,backgroundColor:colors['backgroundLight'][800]}}
                >
                    {headerBannerTitle}
                </ImageBackground>
            </View>
        );

    }


    function topNavBar() {
        return (
            <Flex
                w={'100%'}
                height={'50px'}
                direction={'row'}
                justify={'space-between'}
                alignItems={'center'}
                alignContent={'center'}

                bg={colors['background'][50]}
            >
                <Flex direction={'row'}
                      alignItems={'center'}
                      alignContent={'center'}>
                    {props.withBackButton ?
                        <Button variant={'unstyled'} onPress={goToPreviousPage}>
                            <AntDesign name="arrowleft" size={32} color="#1F2357"/>
                        </Button>
                        :
                        (congress.app_logo ?
                            <Image
                                source={{uri: congress.app_logo,}}
                                alt={'Splash image from sponsor'}
                                resizeMode={'contain'}
                                style={{width: 100, height: 50, marginLeft: 10}}
                            /> : null)
                    }

                </Flex>

                <Flex
                    direction={"row"}
                    align={'center'}
                    justify={'space-around'}
                    pb={2}
                    w={'40%'}
                >
                    <SupportButton
                        text={'Support'}
                        activePage={props.activePage}
                        deviceType={deviceType}

                    />
                    <MenuButton
                        destination={'Profile'}
                        text={t('My_profile')}
                        icon={'account-circle'}
                        iconOnly={true}
                        activePage={props.activePage}
                        deviceType={deviceType}

                    />
                    <MenuButton
                        destination={'UserList'}
                        text={t('My list')}
                        icon={'list'}
                        iconOnly={true}
                        activePage={props.activePage}
                        deviceType={deviceType}

                    />
                </Flex>
            </Flex>
        );
    }

    return (
        <Center flex={1} p={2} bg={colors['background'][50]}>
            <Stack
                m={2}
                mt={Platform.OS === 'web' ? 0 : 4}
                w="100%"
                h="100%"
                maxW='1900px'
                direction={"column-reverse"}
                rounded="xl"
                bg={colors['background'][50]}
            >
                <Flex
                    direction={"row"}
                    justify={"space-around"}
                    pt={4}
                    w={"100%"}
                    borderTopWidth={1}
                    borderColor={colors['backgroundLight'][300]}
                    zIndex={5}
                    style={{boxShadow: '0px 0px 20px -10px #043033', clipPath: 'inset(0px -15px 0px 0px)'}}
                    bg={colors['background'][50]}
                >
                    <MenuButton
                        destination={'Live'}
                        text={t('Live')}
                        icon={'live-tv'}
                        activePage={props.activePage}
                    />
                    <MenuButton
                        destination={'Program'}
                        text={t('Program')}
                        icon={'calendar-today'}
                        activePage={props.activePage}
                    />
                    <MenuButton
                        destination={'Exhibition'}
                        text={t('Exhibition')}
                        icon={'store'}
                        activePage={props.activePage}
                    />
                    {congress.live_map_ready ?
                        <MenuButton
                            destination={'Map'}
                            text={t('Map')}
                            icon={'map'}
                            activePage={props.activePage}
                        />
                        :
                        <MenuButton
                            destination={'Live'}
                            text={t('Map') + ' (soon)'}
                            icon={'map'}
                            activePage={props.activePage}
                        />
                    }
                    <MenuButton
                        destination={'Explore'}
                        text={t('Menu_more')}
                        icon={'more-vert'}
                        activePage={props.activePage}
                    />
                </Flex>

                <Box
                    flex={1}
                    style={{zIndex: 2}}
                >
                    {props.children}
                </Box>

                {topNavBar()}
            </Stack>
        </Center>
    );
}
