import * as React from "react";
//import Pdf from 'react-native-pdf';

import Layout from "../layouts/Layout";
import {Center, Heading, HStack, Text} from "native-base";
import {useTranslation} from "react-i18next";

export default function CompanyFilesScreen(props) {
    const {t, i18n} = useTranslation();

    /*
     <Pdf
                    source={{uri: props.route.params.file}}>
                </Pdf>
     */
    if (props.route.params.file) {
        return (
            <Layout withBackButton activePage={props.navigation.isFocused ? 'CompanyProfile' : ''}>
                <Heading size={'2xl'}>{t('Company_files')}</Heading>
            </Layout>
        );
    } else
        return (
            <Layout activePage={props.navigation.isFocused ? 'CompanyProfile' : ''}>
                <Center>
                    <Text>Sorry, we couldn't find the file you are looking for.</Text>
                </Center>
            </Layout>
        );
}
