import {Box, Button, Center, Flex, Heading, Spinner, Stack, Text, useTheme, View} from "native-base";
import MenuButton from "../components/MenuButton";
import {AntDesign} from "@expo/vector-icons";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import * as ScreenOrientation from "expo-screen-orientation";
import {useEffect, useState} from "react";
import SupportButton from "../components/SupportButton";
import HomeNews from "../components/HomeNews";
import {Image, ImageBackground} from "react-native";
import Config from "../config.json";
import bannerImageAop from "../../assets_aop/homepage_banner.jpg";
import bannerImage from "../../assets/homepage_banner.jpg";
import {useSelector} from "react-redux";

export default function LayoutForTablet(props) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(true);
    const [landscape, setLandscape] = useState(null);
    const {congress} = useSelector(state => state.global);


    useEffect(() => {
        async function setLayout() {
            const orientation = await getDeviceOrientation();

            if(orientation === 3 || orientation === 4) {
                setLandscape(true);
            } else setLandscape(false);

            ScreenOrientation.addOrientationChangeListener(handleOrientationChange);
        }

        setLayout().then(() => setIsLoading(false));
    }, []);


    function handleOrientationChange(e) {
        if((e.orientationInfo.orientation === 3 || e.orientationInfo.orientation === 4)) {
            setLandscape(true);
        }
        else {
            setLandscape(false);
        }
    }

    async function getDeviceOrientation() {
        return await ScreenOrientation.getOrientationAsync();
    }

    function goToPreviousPage() {
        return navigation.goBack();
    }

    function renderHeaderBanner() {
        let headerBannerTitle;
        const typeTitle = congress.app_name.split('-')[0].trim();

        headerBannerTitle = <>
            <Heading  fontSize={'20px'} fontWeight={'medium'} color={'white'} marginLeft={4}>
                {typeTitle}
            </Heading>
            <Text fontSize={'20px'} fontWeight={'bold'} color={colors['action'][50]} mt={'-16px'} marginLeft={4}>
                {congress.name_short}
            </Text>
        </>;
        return (
            <View h={'80px'}  width={'60%'}  borderRadius={20} ml={5} >
                <ImageBackground
                    source={Config.type === 'aop' ? bannerImageAop : bannerImage}
                    imageStyle={{opacity: 0.25,borderRadius: 20}}
                    style={{width: '100%', height: '100%', justifyContent: 'center',borderRadius: 20,backgroundColor:colors['backgroundLight'][800]}}
                >
                    {headerBannerTitle}
                </ImageBackground>
            </View>
        );

    }


    function topNavBar() {
        return (
            <Flex
                w={'100%'}
                direction={'row'}
                height={'100px'}
                justify={'space-between'}
                alignItems={'center'}
                alignContent={'center'}
                bg={colors['background'][50]}
            >
                <Flex direction={'row'}>
                    {props.withBackButton ?
                        <Button variant={'unstyled'} onPress={goToPreviousPage}>
                            <AntDesign name="arrowleft" size={32} color="#1F2357"/>
                        </Button>
                        :
                        (congress.app_logo ?
                            <Image
                                source={{uri: congress.app_logo,}}
                                alt={'Splash image from sponsor'}
                                resizeMode={'contain'}
                                style={{width: 200, height: 150, marginLeft: 50}}
                            /> : null)
                    }
                </Flex>

                <Flex
                    direction={"row"}
                    justify={'space-around'}
                    alignContent={'center'}
                    alignItems={'center'}
                    pb={2}
                    height={75}
                    w={'40%'}
                >
                    <SupportButton
                        activePage={props.activePage}
                    />
                    <MenuButton
                        destination={'Profile'}
                        text={t('My_profile')}
                        icon={'account-circle'}
                        activePage={props.activePage}
                        iconOnly={!landscape}

                    />
                    <MenuButton
                        destination={'UserList'}
                        text={'My list'}
                        icon={'list'}
                        iconOnly={true}
                        activePage={props.activePage}
                    />
                </Flex>
            </Flex>
        );
    }

    if (isLoading) {
        return (
            <Center flex={1} p={4} bg={colors['background'][50]}>
                <Spinner color="#5766D6" />
            </Center>
        );
    }
    else if(landscape === true) {
        return (
            <Center flex={1} p={4} bg={colors['background'][50]}>
                <Stack
                    m={4}
                    w="100%"
                    h="100%"
                    maxW='1900px'
                    direction={"row"}
                    align={"left"}
                    justify={"flex-start"}
                    rounded="xl"
                    bg={colors['background'][50]}
                >
                    <Flex
                        direction={"column"}
                        justify={"flex-start"}
                        align={"flex-start"}
                        pt={20}
                        h={"70%"}
                        borderRightWidth={1}
                        borderTopWidth={0}
                        borderColor={colors['backgroundLight'][700]}
                        zIndex={5}
                        style={{boxShadow: '0px 0px 20px -10px #043033', clipPath: 'inset(0px -15px 0px 0px)'}}
                    >
                        <MenuButton
                            destination={'Live'}
                            text={t('Live')}
                            icon={'live-tv'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />
                        <MenuButton
                            destination={'Program'}
                            text={t('Program')}
                            icon={'calendar-today'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />
                        <MenuButton
                            destination={'Exhibition'}
                            text={t('Exhibition')}
                            icon={'store'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />
                        {congress.live_map_ready ?
                            <MenuButton
                                destination={'Map'}
                                text={t('Map')}
                                icon={'map'}
                                activePage={props.activePage}
                                landscape={landscape}
                            />
                            :
                            <MenuButton
                                destination={'Live'}
                                text={t('Map') + ' (soon)'}
                                icon={'map'}
                                activePage={props.activePage}
                                landscape={landscape}
                            />
                        }
                        <MenuButton
                            destination={'Explore'}
                            text={t('Explore')}
                            icon={'search'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />
                        {/*<SupportButton*/}
                        {/*    text={'Support'}*/}
                        {/*    activePage={props.activePage}*/}
                        {/*/>*/}
                        {/*<MenuButton*/}
                        {/*    destination={'Profile'}*/}
                        {/*    text={t('My_profile')}*/}
                        {/*    icon={'account-circle'}*/}
                        {/*    activePage={props.activePage}*/}
                        {/*/>*/}

                    </Flex>

                    <Box
                        flex={3}
                        style={{zIndex: 2}}
                        borderColor={colors['backgroundLight'][700]}

                    >
                        {topNavBar()}

                        <Flex direction={"column"} flex={1}>
                            {props.children}
                        </Flex>

                    </Box>
                </Stack>
            </Center>
        );
    }
    else if(landscape === false) {
        return (
            <Center flex={1} p={4} bg={colors['background'][50]}>
                <Stack
                    m={4}
                    w="100%"
                    h="100%"
                    maxW='1900px'
                    direction={"column-reverse"}
                    rounded="xl"
                    bg={colors['background'][50]}
                >
                    <Flex
                        direction={"row"}
                        justify={"space-around"}
                        pt={4}
                        w={"100%"}
                        borderTopWidth={1}
                        borderColor={colors['backgroundLight'][50]}
                        zIndex={5}
                        style={{boxShadow: '0px 0px 20px -10px #043033', clipPath: 'inset(0px -15px 0px 0px)'}}
                        bg={colors['background'][50]}
                    >
                        <MenuButton
                            destination={'Live'}
                            text={t('Live')}
                            icon={'live-tv'}
                            activePage={props.activePage}
                            landscape={landscape}
                        />
                        <MenuButton
                            destination={'Program'}
                            text={t('Program')}
                            icon={'calendar-today'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />
                        <MenuButton
                            destination={'Exhibition'}
                            text={t('Exhibition')}
                            icon={'store'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />
                        {congress.live_map_ready ?
                            <MenuButton
                                destination={'Map'}
                                text={t('Map')}
                                icon={'map'}
                                activePage={props.activePage}
                                landscape={landscape}
                            />
                            :
                            <MenuButton
                                destination={'Live'}
                                text={t('Map') + ' (soon)'}
                                icon={'map'}
                                activePage={props.activePage}
                                landscape={landscape}
                            />
                        }
                        <MenuButton
                            destination={'Explore'}
                            text={t('Explore')}
                            icon={'search'}
                            activePage={props.activePage}
                            landscape={landscape}

                        />

                    </Flex>

                    <Box
                        flex={1}
                        style={{zIndex: 2}}
                    >
                        {props.children}
                    </Box>

                    {topNavBar()}
                </Stack>
            </Center>
        );
    }
    else return <></>;
}
