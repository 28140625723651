import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as Linking from 'expo-linking';
import {Link, NavigationContainer, useNavigationContainerRef} from "@react-navigation/native";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {SafeAreaView, StyleSheet, Platform} from "react-native";
import HomeScreen from "../screens/HomeScreen";
import LoginScreen from "../screens/LoginScreen";
import ExploreScreen from "../screens/ExploreScreen";
import MapScreen from "../screens/MapScreen";
import ProgramScreen from "../screens/ProgramScreen";
import ExhibitionScreen from "../screens/ExhibitionScreen";
import ProfileScreen from "../screens/ProfileScreen";
import SpeakersListScreen from "../screens/SpeakersListScreen";
import UserProfileScreen from "../screens/UserProfileScreen";
import PersonalInformationsScreen from "../screens/PersonalInformationsScreen";
import {useEffect, useRef, useState} from "react";
import {logout, refreshData} from "../actions/auth";
import CompanyListScreen from "../screens/CompanyListScreen";
import SessionScreen from "../screens/SessionScreen";
import {useTranslation} from "react-i18next";
import EpostersListScreen from "../screens/EpostersListScreen";
import EposterScreen from "../screens/EposterScreen";
import CompanyProfileScreen from "../screens/CompanyProfileScreen";
import NewAccountScreen from "../screens/NewAccountScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import UserListScreen from "../screens/UserListScreen";
import PhotoBoothScreen from "../screens/PhotoBoothScreen";
import {refreshNotifications} from "../actions/notifications";
import CompanyFilesScreen from "../screens/CompanyFilesScreen";
import TermsOfUseScreen from "../screens/TermsOfUseScreen";
import GeneralInfosScreen from '../screens/GeneralInfosScreen';
import {clearCongress, initCongress} from "../actions/global";
import {Center, Heading, View, Text, useTheme, Image, Flex, Spacer, Button, Spinner, Modal, Divider} from "native-base";
import LiveStageScreenForDesktop from "../screens/LiveStageScreenForDesktop";
import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Localization from 'expo-localization';
import LiveChangeRoomScreen from "../screens/LiveChangeRoomScreen";
import ReplaySessionListScreen from "../screens/ReplaySessionListScreen";
import LiveSessionListScreen from "../screens/LiveSessionListScreen";
import HttpService from "../services/HttpService";
import LiveStageScreen from "../screens/LiveStageScreen";
import PartnerListScreen from "../screens/PartnerListScreen";
import MyBadgeScreen from "../screens/MyBadgeScreen";
import KioskScreen from "../screens/KioskScreen";
import LiveStagePhysicalScreen from "../screens/LiveStagePhysicalScreen";
import {WelcomePackScreen} from "../screens/WelcomePackScreen";
import TrainingLabsAndHospitalitySuitesScreen from "../screens/TrainingLabsAndHospitalitySuitesScreen";
import Config from "../config.json";
import * as Updates from 'expo-updates';
import OnsiteMapScreen from "../components/OnsiteMapScreen";
import LoadingFallback from "../components/LoadingFallback";
import StatsScreen from "../screens/StatsScreen";
import pusher from "../pusher";
import LecturesScreen from "../screens/LecturesScreen";
import FirebaseAnalytics from '../services/Analytics/Analytics';

const Stack = createNativeStackNavigator();

const playstoreImg = require('../../assets/download_google_play.png');
const appstoreImg = require('../../assets/download_app_store.png');

const prefix = Linking.createURL('/');
const config = {
    screens: {
        Homepage: '',
        Login: 'login',
        Live: 'live',
        LiveStageScreenForDesktop: 'live-stage-desktop/:id',
        LiveStageScreenForMobile: 'live-stage/:id',
        LiveStagePhysicalScreen: 'live-stage-physical/:id',
        LiveChangeRoom: 'live-room-change/:id/:id2',
        Map: 'map/:id?/:type?',
        Program: 'program',
        Session: 'session/:id',
        Exhibition: 'exhibition',
        Explore: 'explore',
        Profile: 'profile',
        Message: 'message',
        Speakers: 'Speakers',
        UserProfile: 'user/:id',
        CompanyProfile: 'company/:id',
       /* Eposters: 'e-posters',
        Eposter: 'e-poster',*/
        PersonalInformations: 'my-profile',
        CompanyList: 'CompanyList',
        NewAccount: 'my-new-account',
        UserList: 'my-list',
        PhotoBooth: 'photo-booth',
        CompanyFiles: 'company-files',
        TermsOfUse: 'terms-of-use',
        LiveSessionList: 'live-session-list',
        ReplaySessionList: 'replay-session-list',
        PartnerList: 'partner-list',
        MyBadge: 'my-badge',
        Kiosk: 'kiosk',
        TrainingLabsAndHospitalitySuites: 'training-labs-and-hospitality-suites',
        GeneralInfos: 'general-infos',
        WelcomePack: 'welcome-pack',
        OnsiteMap: 'onsite-map',
        Stats: 'stats',
        Lectures: 'lectures'
    }
}

export default function ResponsiveNavigation() {
    const [platformClosed, setPlatformClosed] = useState(false);
    const [platformSoon, setPlatformSoon] = useState(false);
    const [fetchError, setFetchError] = useState();
    const [isReady, setIsReady] = useState(false);
    const {isLoggedIn, language, congressUser, user} = useSelector(state => state.auth);
    const {congress} = useSelector(state => state.global);
    const navigationRef = useNavigationContainerRef();
    const routeNameRef = useRef();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const {colors} = useTheme();
    const linking = {
        prefixes: [prefix],
        config
    };
    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    const [showModal, setShowModal] = useState(false);

    const storeIntendedUrl = async () => {

        if (Platform.OS === 'web') {
            if (window.location.href.indexOf("login") == -1 && window.location.href.indexOf("terms-of-use") == -1 && window.location.href.indexOf("welcome-pack") == -1) {
                try {
                    await AsyncStorage.setItem('login-intended-url', window.location.href);
                } catch (e) {
                    return false;
                }
            }
        }

    }

    /**
     * update user data
     */
    useEffect(
        () => {
            setIsReady(false);
            if (isLoggedIn) {
                dispatch(refreshData()).then(() => {
                        if (language === 'auto') {
                            let deviceLocale = Localization.locale.split('-')[0];
                            if (Config.type == 'imcas' && (deviceLocale !== 'es' && deviceLocale !== 'pt')) {
                                deviceLocale = 'en';
                            }
                            if(Config.type == 'aop'){
                                deviceLocale = 'fr';
                            }
                            i18n.changeLanguage(deviceLocale);
                            setIsReady(true);
                        } else {
                            i18n.changeLanguage(language);
                            setIsReady(true);
                        }
                    }, (err) => {
                        setFetchError(err)
                        setIsReady(true);
                    }
                );

                analyticsSetUser();

            } else {
                dispatch(clearCongress()).then(() => {
                    setIsReady(true);
                }, (err) => {
                    setFetchError(err)
                    setIsReady(true);
                });
            }

            dispatch(initCongress()).then(() => {
                setIsReady(true);
            }, (err) => {
                setFetchError(err);
                setIsReady(true);
            });
        }, [isLoggedIn, language]
    )

    const analyticsSetUser = async () => {
        if (user) {
            await FirebaseAnalytics.sendUserId(user.id);
            await FirebaseAnalytics.sendUserProperties({
                app_environment: Config.environment,
                app_version: Config.appVersion,
                app_build: Config.build,
                app_type: Config.type,
            });
        }
    }

    /* Pusher */
    useEffect(
        () => {

            if (user) {
                const channelUser = pusher.subscribe('user-' + user.id);
                channelUser.bind('notification-new', function (data) {
                    dispatch(refreshNotifications());
                });
                channelUser.bind('list-update', function (data) {
                    console.log('list-update');
                });
                channelUser.bind('login', async function (data) {
                    const authToken = await AsyncStorage.getItem('auth-token');
                    if (authToken && (data.token != authToken) && !data.allow_website_multiple_login) {
                        dispatch(logout());
                    }
                });
            }

            const channelGlobal = pusher.subscribe('live-global');
            channelGlobal.bind('reload', function (data) {
                console.log('reload');
            });
            channelGlobal.bind('redirect', function (data) {
                console.log('redirect to' + data.to);
            });

            channelGlobal.bind('update-app', function (data) {
                checkAndReloadIfUpdateIsAvailable();
            });

            checkAndReloadIfUpdateIsAvailable();

        }, []
    );

    useEffect(() => {
        checkPlatformStatus()
            .then((r) => {
                if (r.data === 'soon') setPlatformSoon(true);
                else if (r.data === 'off') setPlatformClosed(true);
            })
            .catch((err) => setFetchError(err.message));
    }, [])


    async function checkAndReloadIfUpdateIsAvailable() {
        if (Config.environment === 'production') {
            if (Platform.OS === 'android' || Platform.OS === 'ios') {
                const update = await Updates.checkForUpdateAsync();
                if (update.isAvailable) {
                    setShowModal(true);
                }
            } else {
                // Reload web
            }
        }
    }

    function hideModal() {
        setShowModal(false);
    }

    async function updateApp() {
        await Updates.fetchUpdateAsync();
        Updates.reloadAsync();
    }

    function checkPlatformStatus() {
        return HttpService.getData('congress/status');
    }

    function handlePressToPlaystore() {
        let url = 'https://play.google.com/store/apps/details?id=com.imcas.academy';
        if (Config.extra.type === 'aop') url = 'https://play.google.com/store/apps/details?id=com.aop.academy';
        return Linking.openURL(url);
    }

    function handlePressToAppstore() {
        let url = 'https://apps.apple.com/us/app/imcas-academy/id1410088543';
        if (Config.extra.type === 'aop') url = 'https://apps.apple.com/fr/app/aop-academy/id1438836126';
        return Linking.openURL(url);
    }

    function setRouteName() {
        return routeNameRef.current = navigationRef.getCurrentRoute().name;
    }

    function renderPlatformSoonScreen() {
        const platform = Config.type === 'aop' ? 'AOP' : 'IMCAS';
        return (
            <SafeAreaView style={styles.container}>
                <Center flex={1} px={28}>
                    <View bg={colors['backgroundLight']['900']} px={4} py={6} borderRadius={5} maxW={900}>
                        <Heading size={{base: 'md', md: 'lg'}}>{t('Dear_visitor')},</Heading>
                        <Text fontSize={{base: 'sm', md: 'md'}}>{t('Live_platform_soon_date')}</Text>
                        {renderLinkToAcademy(platform)}
                    </View>
                </Center>
            </SafeAreaView>
        );
    }


    function renderPlatformClosedScreen() {
        const platform = Config.type === 'aop' ? 'AOP' : 'IMCAS';

        return (
            <SafeAreaView style={styles.container}>
                <Center flex={1} px={28}>
                    <View bg={colors['backgroundLight']['50']} px={4} py={6} borderRadius={5}>
                        <Heading size={'md'} pb={2}>{t('Dear_visitor')},</Heading>
                        <Text fontSize={{base: 'sm', md: 'md'}}>{t('Live_platform_closed')}</Text>
                        <Text fontSize={{base: 'sm', md: 'md'}}>
                            {t('Join')} {platform} Academy {t('To_get_replay_and_continue_learning')}
                        </Text>
                        {renderLinkToAcademy(platform)}
                    </View>
                </Center>
            </SafeAreaView>
        );
    }

    function renderLinkToAcademy(platform) {
        if (Platform.OS === 'web') {
            return (
                <Center>
                    <View mt={6}
                          bg={colors['action'][50]}
                          borderRadius={5}
                    >
                        <a href={Config.type === 'aop' ? 'https://www.aopcongress.com/academy' : 'https://www.imcas.com/academy'}
                           target={'_blank'}
                           style={{
                               width: '100%',
                               height: '100%',
                               padding: '15px 50px 15px 50px',
                               textDecoration: 'none',
                           }}
                        >
                            <Text fontSize={'lg'} color={'white'}>{platform} ACADEMY</Text>
                        </a>
                    </View>
                </Center>
            );
        } else {
            return (
                <Flex direction={'row'} justify={'space-around'}>
                    <Button
                        variant={'unstyled'} flex={5} p={0}
                        onPress={handlePressToPlaystore}
                    >
                        <Image
                            source={playstoreImg}
                            alt={'Playstore'}
                            resizeMode={'contain'}
                        />
                    </Button>
                    <Spacer flex={1}/>
                    <Button
                        variant={'unstyled'} flex={5} p={0}
                        onPress={handlePressToAppstore}
                    >
                        <Image
                            source={appstoreImg}
                            alt={'App Store'}
                            resizeMode={'contain'}
                        />
                    </Button>
                </Flex>
            );
        }
    }

    if (!isReady) {
        return (
            <SafeAreaView style={styles.container}>
                <Center flex={1}>
                    <Spinner color="#5766D6" />
                </Center>
            </SafeAreaView>
        );
    } else if (fetchError) {
        return (
            <SafeAreaView style={styles.container}>
                <Center flex={1}>
                    <Text>
                        Sorry, it seems there has been a problem with your network. Please restart the application.
                    </Text>
                </Center>
            </SafeAreaView>
        );
    } else if (platformClosed) {
        return renderPlatformClosedScreen();
    } else if (platformSoon) {
        return renderPlatformSoonScreen();
    } else if (Platform.OS === "web" && isMobile) {
        return (
            <SafeAreaView style={styles.container}>
                <Center flex={1} px={28}>
                    <View bg={colors['backgroundLight'][50]} px={4} py={6} borderRadius={5}>
                        <Heading size={'sm'}>Dear visitor,</Heading>
                        <Text fontSize={'xs'} color={'white'}>It looks like you are trying to
                            access {Config.type === 'aop' ? 'AOP' : 'IMCAS'} Live website on a mobile
                            browser.</Text>
                        <Text fontSize={'xs'} color={'white'}>We recommend you download our free application
                            instead.</Text>
                        <Flex fontSize={'xs'} direction={'row'} justify={'space-around'} my={4}>
                            <a href={Config.type === 'aop' ? 'https://play.google.com/store/apps/details?id=com.aop.live' : 'https://play.google.com/store/apps/details?id=com.imcas.live'}
                               target={'_blank'}
                               style={{flex: 5}}
                            >
                                <Image
                                    source={playstoreImg}
                                    alt={'Playstore'}
                                    style={{width: '100%', height: '3rem'}}
                                    resizeMode={'contain'}
                                />
                            </a>
                            <Spacer flex={1}/>
                            <a href={Config.type === 'aop' ? 'https://apps.apple.com/us/app/imcas-live/id1590873790' : 'https://apps.apple.com/us/app/imcas-live/id1590873790'}
                               target={'_blank'}
                               style={{flex: 5}}
                            >
                                <Image
                                    source={appstoreImg}
                                    alt={'App Store'}
                                    style={{width: '100%', height: '3rem'}}
                                    resizeMode={'contain'}
                                />
                            </a>
                        </Flex>
                        <Text fontSize={'xs'} color={'white'}>If you prefer to browse the website, please use a computer
                            or a tablet to enjoy the
                            full {Config.type === 'aop' ? 'AOP' : 'IMCAS'} Live experience.</Text>

                    </View>
                </Center>
            </SafeAreaView>
        );
    } else if (congress) {
        if (isLoggedIn) {
            // Users with old accounts or who never had an account and were given temporary credentials
            if (congressUser && !user.website_ready) {
                return (
                    <NavigationContainer
                        linking={linking} fallback={<LoadingFallback/>}
                    >
                        <SafeAreaView style={styles.container}>
                            <Stack.Navigator
                                screenOptions={{headerShown: false}}
                            >
                                <Stack.Screen name={"NewAccount"} component={NewAccountScreen}
                                              options={{title: congress.app_name}}/>
                            </Stack.Navigator>
                        </SafeAreaView>
                    </NavigationContainer>
                )
            }
            // Users with active but uncompleted accounts
            else if (congressUser && !congressUser.profile_completed) {
                return (

                    <NavigationContainer
                        linking={linking} fallback={<LoadingFallback/>}
                    >
                        <SafeAreaView style={styles.container}>
                            <Stack.Navigator
                                screenOptions={{headerShown: false}}
                            >
                                <Stack.Screen name={'PersonalInformations'} component={PersonalInformationsScreen}
                                              options={{title: congress.app_name}}/>
                            </Stack.Navigator>
                        </SafeAreaView>
                    </NavigationContainer>)
            } else if (congressUser) {
                return (
                    <NavigationContainer
                        linking={linking} fallback={<LoadingFallback/>}
                        ref={navigationRef}
                        onReady={setRouteName}
                        onStateChange={async () => {
                            const previousRouteName = routeNameRef.current;
                            const currentRouteName = navigationRef.getCurrentRoute().name;
                            if (previousRouteName !== currentRouteName) {
                               await FirebaseAnalytics.sendAnalyticsEventAsync('screen_view', {
                                    screen_name: currentRouteName,
                                    //screen_id: 1337
                                });
                            }
                            routeNameRef.current = currentRouteName;
                        }}
                    >
                        <SafeAreaView style={styles.container}>
                            <Stack.Navigator
                                screenOptions={{headerShown: false}}
                            >
                                <Stack.Screen name={'Live'} component={HomeScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'LiveStageScreenForDesktop'} component={LiveStageScreenForDesktop}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'LiveChangeRoom'} component={LiveChangeRoomScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Map'} component={MapScreen} options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Program'} component={ProgramScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Session'} component={SessionScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Exhibition'} component={ExhibitionScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Explore'} component={ExploreScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Profile'} component={ProfileScreen}
                                              options={{title: congress.app_name}}/>
                                {/*<Stack.Screen name={'Message'} component={MessageScreen} options={{title: congress.app_name}} />*/}
                                <Stack.Screen name={'Speakers'} component={SpeakersListScreen}
                                              options={{title: congress.app_name}}/>
                               {/* <Stack.Screen name={'Eposters'} component={EpostersListScreen}
                                              options={{title: congress.app_name}}/>*/}
                            {/*    <Stack.Screen name={'Eposter'} component={EposterScreen}
                                              options={{title: congress.app_name}}/>*/}
                                <Stack.Screen name={'UserProfile'} component={UserProfileScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'CompanyProfile'} component={CompanyProfileScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'PersonalInformations'} component={PersonalInformationsScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'CompanyList'} component={CompanyListScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'UserList'} component={UserListScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'PhotoBooth'} component={PhotoBoothScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'CompanyFiles'} component={CompanyFilesScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'TermsOfUse'} component={TermsOfUseScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'LiveSessionList'} component={LiveSessionListScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'ReplaySessionList'} component={ReplaySessionListScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'LiveStageScreenForMobile'} component={LiveStageScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'LiveStagePhysicalScreen'} component={LiveStagePhysicalScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'PartnerList'} component={PartnerListScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'MyBadge'} component={MyBadgeScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Kiosk'} component={KioskScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'GeneralInfos'} component={GeneralInfosScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'TrainingLabsAndHospitalitySuites'}
                                              component={TrainingLabsAndHospitalitySuitesScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Stats'}
                                              component={StatsScreen}
                                              options={{title: congress.app_name}}/>
                                <Stack.Screen name={'Lectures'} component={LecturesScreen} options={{title: congress.app_name}}/>
                            </Stack.Navigator>
                        </SafeAreaView>
                    </NavigationContainer>
                )
            }

        } else {

            storeIntendedUrl();

            return (
                <NavigationContainer
                    linking={linking} fallback={<LoadingFallback/>}
                >
                    <SafeAreaView style={styles.container}>
                        <Stack.Navigator
                            screenOptions={{headerShown: false}}
                        >
                            <Stack.Screen name={"Login"} component={LoginScreen}
                                          options={{title: congress.app_name}}/>
                            <Stack.Screen name={"TermsOfUse"} component={TermsOfUseScreen}
                                          options={{title: congress.app_name}}/>
                            <Stack.Screen name={"ResetPassword"} component={ResetPasswordScreen}
                                          options={{title: congress.app_name}}/>
                            <Stack.Screen name={"WelcomePack"} component={WelcomePackScreen}
                                          options={{title: congress.app_name}}/>
                            <Stack.Screen name={"OnsiteMap"} component={OnsiteMapScreen}
                                          options={{title: congress.app_name}}/>
                            <Stack.Screen name={'CompanyProfile'} component={CompanyProfileScreen}
                                          options={{title: congress.app_name}}/>

                        </Stack.Navigator>
                    </SafeAreaView>
                </NavigationContainer>
            )
        }
    } else {
        return (
            <SafeAreaView style={styles.container}>
                <Center flex={1} bg={colors['background'][50]}>

                </Center>
            </SafeAreaView>
        );
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
});
