import HttpService from "../services/HttpService";
import {useEffect, useState} from "react";
import {format, parseISO} from "date-fns";
import {Flex, Spinner, Text, useTheme, View} from "native-base";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import ViewAll from "./ViewAll";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Lecture from "./Lecture";

function NextLecture() {
    const [speaker, setSpeaker] = useState({});
    const {t, i18n} = useTranslation()
    const {user} = useSelector(state => state.auth);
    const currentDay = format(Date.now(), 'y-MM-dd HH:mm:ss');
    const current = format( Date.now(), 'y-MM-dd');
    const [filteredLecture, setFilteredLectures] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {colors} = useTheme();
    function getSpeaker() {
        if (isLoading) {
            return null;
        }
        setIsLoading(true);
        return HttpService
            .getData(`user/get/speaker/${user.id}`).then(response => {
                setSpeaker(response.data);
            })
            .catch(error => {
                console.log(error.data);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getSpeaker();
    }, []);

    useEffect(() => {
        if(Object.keys(speaker).length > 0 && speaker.data && speaker.data.lectures) {
            setFilteredLectures(speaker.data.lectures.filter(lecture => !lecture.is_eposter).filter(lecture => {
                if (lecture.session)
                {
                    if (format(new Date(parseISO(lecture.session.datetime_begin)), 'y-MM-dd') === current)
                    {
                        return new Date(parseISO(lecture.session.datetime_begin)) >= new Date(parseISO(currentDay));
                    }
                }
            }).sort((a,b) => {new Date(parseISO(a.session.datetime_begin)) - new Date(parseISO(b.session.datetime_begin))}));
            setIsLoading(false);
        }
    }, [speaker, currentDay]);

    return (
        <>
            {isLoading ?
                <View alignItems={"center"} w={'100%'} h={'100%'} justifyContent={"center"}>
                    <Spinner size="lg" color={colors['backgroundLight'][300]}/>
                </View>
                :
                <>
                    {
                        filteredLecture && filteredLecture.length > 0 ?
                            <>
                                <Flex direction={'row'} align={'center'} width={{base:'100%',md:'95%'}} justifyContent={'space-between'} mb={2} ml={{base:0,lg:4}}>
                                    <Flex direction={'row'} align={'center'}>
                                        <MaterialCommunityIcons name="human-male-board" size={32} color={"#1F2357"} />
                                        <Text fontSize={"lg"} fontWeight="bold" ml={2}>{t('my_next_lecture')}</Text>
                                    </Flex>
                                    <ViewAll to={'Lectures'}></ViewAll>
                                </Flex>

                                <Lecture lecture={filteredLecture[0]}></Lecture>
                            </>
                            :
                            <></>
                    }
                </>
            }
        </>
    );
}

export default NextLecture;
