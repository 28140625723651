import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import {Platform} from "react-native";
import Config from "../config.json";

const baseURL = Config.apiUrl;

const postData = async (url, data) => {
    return axios.post(baseURL + url, data,
        {
            headers: await authHeader()
        }
    );
}

const getData = async (url) => {
    return axios.get(baseURL + url,
        {
            headers: await authHeader()
        }
    );
}

const authHeader = async () => {
    const authToken = await AsyncStorage.getItem('auth-token');
    const language = await AsyncStorage.getItem('language');
    const type = Config.type;
    const appBuild = Config.build;
    const appVersion = Config.appVersion;
    const appVersionCode = Config.appVersionCode;
    const appPlatformOS = Platform.OS;
    if (authToken) {
        return {
            'Accept': 'application/json',
            'x-imcas-language': language,
            'x-imcas-type': type,
            'x-imcas-app-build': appBuild,
            'x-imcas-app-version': appVersion,
            'x-imcas-app-version-code': appVersionCode,
            'x-imcas-app-platform-os': appPlatformOS,
            'Authorization': 'Bearer ' + authToken
        };
    } else {
        return {
            'Accept': 'application/json',
            'x-imcas-language': language,
            'x-imcas-type': type,
            'x-imcas-app-build': appBuild,
            'x-imcas-app-version': appVersion,
            'x-imcas-app-version-code': appVersionCode,
            'x-imcas-app-platform-os': appPlatformOS,
        };
    }
}

export default {
    postData,
    getData,
};
