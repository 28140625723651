import {Box, Button, Center, Flex, Heading, Stack, Text, useTheme, View} from "native-base";
import {AntDesign} from "@expo/vector-icons";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useNavigation} from "@react-navigation/native";
import MenuLink from "../components/MenuLink";
import SupportButton from "../components/SupportButton";
import MenuButton from "../components/MenuButton";
import {Dimensions, Image, ImageBackground} from "react-native";
import Config from "../config.json";
import bannerImageAop from "../../assets_aop/homepage_banner.jpg";
import bannerImage from "../../assets/homepage_banner.jpg";
import {useSelector} from "react-redux";



export default function LayoutForDesktop(props) {
    const {colors} = useTheme();
    const {t, i18n} = useTranslation();
    const navigation = useNavigation();
    const {congress} = useSelector(state => state.global);


    function goToPreviousPage() {
        return navigation.goBack();
    }

    function renderHeaderBanner() {
        let headerBannerTitle;
        const typeTitle = congress.app_name.split('-')[0].trim();

        headerBannerTitle = <>
            <Heading  fontSize={'20px'} fontWeight={'medium'} color={'white'} marginLeft={4}>
                {typeTitle}
            </Heading>
            <Text fontSize={'20px'} fontWeight={'bold'} color={colors['action'][50]} mt={'-16px'} marginLeft={4}>
                {congress.name_short}
            </Text>
        </>;
        return (
            <View h={'80px'}  borderRadius={20} ml={5}  >
                <ImageBackground
                    source={Config.type === 'aop' ? bannerImageAop : bannerImage}
                    imageStyle={{opacity: 0.25,borderRadius: 20}}
                    style={{width: '100%', height: '100%', justifyContent: 'center', paddingRight: 200,borderRadius: 20,backgroundColor:colors['backgroundLight'][800]}}
                >
                    {headerBannerTitle}
                </ImageBackground>
            </View>
        );

    }

    return (
        <Center flex={1} p={4} bg={colors['background'][50]}>
            <Stack
                m={4}
                w="100%"
                h="100%"
                maxW='1900px'
                direction={"row"}
                rounded="xl"
                bg={colors['background'][50]}
            >
                <Flex
                    direction={"column"}
                    justify={"flex-start"}
                    pt={4}
                    pl={2}
                    borderRightWidth={1}
                    borderTopWidth={0}
                    borderColor={colors['backgroundLight'][50]}
                    zIndex={5}
                    style={{boxShadow: '0px 0px 20px -10px #043033', clipPath: 'inset(0px -15px 0px 0px)'}}
                >

                    <MenuLink
                        destination={'Live'}
                        text={t('Live')}
                        icon={'live-tv'}
                        activePage={props.activePage}
                    />
                    <MenuLink
                        destination={'Program'}
                        text={t('Program')}
                        icon={'calendar-today'}
                        activePage={props.activePage}
                    />
                    <MenuLink
                        destination={'Exhibition'}
                        text={t('Exhibition')}
                        icon={'store'}
                        activePage={props.activePage}
                    />
                    {congress.live_map_ready ?
                        <MenuLink
                            destination={'Map'}
                            text={t('Map')}
                            icon={'map'}
                            activePage={props.activePage}
                        />
                        :
                        <MenuLink
                            destination={'Live'}
                            text={t('Map') + ' (soon)'}
                            icon={'map'}
                            activePage={props.activePage}
                        />
                    }
                    <MenuLink
                        destination={'Explore'}
                        text={t('Menu_more')}
                        icon={'more-horiz'}
                        activePage={props.activePage}
                    />
                </Flex>

                <Box
                    flex={3}
                    px={6}
                    style={{zIndex: 2}}
                >
                    <Flex
                        w={'100%'}
                        direction={'row'}
                        height={'70px'}
                        justify={'space-between'}
                        alignItems={'center'}
                        alignContent={'center'}
                        bg={colors['background'][50]}
                    >
                        <Flex direction={'row'}>
                            {props.withBackButton?
                                <Button variant={'unstyled'} onPress={goToPreviousPage}>
                                    <AntDesign name="arrowleft" size={32} color="#1F2357"/>
                                </Button>
                                :
                                (congress.app_logo ?
                                <Image
                                    source={congress.app_logo}
                                    alt={'Splash image from sponsor'}
                                    resizeMode={'contain'}
                                    style={{width: 250, height: 200, marginLeft: 50}}
                                /> : null)
                            }
                        </Flex>

                        <Flex
                            direction={"row"}
                            justify={'space-around'}
                            alignContent={'center'}
                            alignItems={'center'}
                            pb={2}
                            height={75}
                            w={'30%'}
                        >
                            <SupportButton
                                text={'Support'}
                                activePage={props.activePage}
                            />
                            <MenuButton
                                destination={'Profile'}
                                text={t('My_profile')}
                                icon={'account-circle'}
                                activePage={props.activePage}
                                iconOnly={Dimensions.get('window').width < 1300}

                            />
                            <MenuButton
                                destination={'UserList'}
                                text={'My list'}
                                icon={'list'}
                                iconOnly={true}
                                activePage={props.activePage}
                            />
                        </Flex>
                    </Flex>
                    {props.children}
                </Box>
            </Stack>
        </Center>
    );
}
